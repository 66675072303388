@import "../../assets/globalStyles/variables";

.RewardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3vh;
}

.InnerContainer{
  width: 80%;
  max-width: 600px;
  padding: 5px;
  --b:3px;   /* thickness of the border */
  --c:#0d8aa6;   /* color of the border */
  --w:20px;  /* width of border */
  
  border:var(--b) solid transparent; /* space for the border */
  --g:#0000 90deg,var(--c) 0;
  background:
    conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--g)) 0    0,
    conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--g)) 100% 100%;
  background-size:var(--w) var(--w);
  background-origin:border-box;
  background-repeat:no-repeat;
}

.PrimaryLargeText {
  color: $primary;
  font-size: x-large;
}

.RewardText {
  color: $secondary;
  font-size: x-large;
}
