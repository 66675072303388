@import "assets/globalStyles/variables";

.TicketRow {
  margin: 3vh auto;
  padding: 6% 4%;
  border: 2px dashed $dark-primary;
  h2,
  h4,
  p {
    margin: 0;
    padding: 0 3%;
    text-align: left;
  }
  h2 {
    margin-top: -6%;
    line-height: inherit;
  }
}

.Clickable {
  box-shadow: 4px 8px 20px rgba($dark-primary, 0.16);
}

.IconCol {
  display: flex;
  span {
    font-size: 50px;
    color: $secondary;
    margin: auto;
  }
}

.DisabledTicketRow {
  h2,
  h4,
  p,
  svg {
    color: gray;
  }
  p {
    font-style: italic;
  }
}
