// Button
.ant-btn {
  height: fit-content;
}
.ant-btn-ghost {
  color: $white !important;
  border-color: $dark-primary;
  background: $dark-primary;
}
.ant-btn-primary {
  h4,
  h5 {
    color: $pale-primary;
    margin: auto;
  }
  border-color: $secondary;
  background: $secondary;
}
.ant-btn-default {
  h4,
  h5 {
    margin: auto;
    color: $dark-primary;
  }
  border-color: $dark-primary;
  background: $pale-primary;
}
.ant-btn-default.dark {
  h4,
  h5 {
    color: white;
  }
  background-color: rgba($primary-font, 0.1);
  border-color: white;
  &:hover,
  &:focus {
    border-color: $gray3;
    background-color: rgba($primary-font, 0.22);
    h4,
    h5 {
      color: $disabled-gray;
    }
  }
}
.ant-btn:hover,
.ant-btn:focus {
  color: $disabled-gray;
  border-color: $dark-primary;
  background: $white;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  border-color: $pale-secondary;
  background: $pale-secondary;
}
.ant-btn-default:hover,
.ant-btn-default:focus {
  background-color: $primary;
  background: $blocked-gray;
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: $dark-primary;
  border-color: $dark-primary;
  background: $white;
}
.ant-btn-link,
.ant-btn-text {
  color: $dark-primary;
  border-color: transparent;
}
// Input
.ant-input,
.ant-input-password,
.ant-input-affix-wrapper {
  background-color: $pale-primary;
  border-radius: 4px;
  color: $primary-font;
  font-size: 16px;
  padding: 8px 22px;
  :not(.ant-form-item-has-error) {
    border: transparent;
  }
}
.ant-input::placeholder {
  color: rgba($color: $primary-font, $alpha: 0.4);
}

// InputNumber
.ant-input-number {
  background-color: $pale-primary;
  font-size: 16px;
}
.ant-input-number-affix-wrapper {
  background-color: $pale-primary;
  border-radius: 4px;
  padding: 8px 22px;
  color: $primary-font !important;
  font-size: 16px;
  width: 100%;
  :not(.ant-form-item-has-error) {
    border: transparent;
  }
}
.ant-input-number-input::placeholder {
  color: rgba($color: $primary-font, $alpha: 0.4);
}

// Checkbox
.ant-checkbox-inner {
  height: 20px;
  width: 20px;
  border: 1px solid $primary-font;
}
.ant-checkbox-wrapper {
  color: $primary-font;
  font-size: 14px;
  font-weight: 500;
  height: fit-content;
}
.ant-checkbox-wrapper::selection,
.ant-checkbox-input::selection {
  background: $secondary;
}
.ant-checkbox-checked::after {
  border: 1px solid $secondary;
}
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $secondary;
    border-color: $secondary;
  }
  &:after {
    border-color: secondary;
    background: secondary;
  }
  &:focus {
    border-color: secondary;
    background: secondary;
  }
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $secondary;
}
.ant-checkbox-inner::after {
  height: 14px;
  width: 7px;
  top: 7px;
}

// Select
.ant-select,
.ant-select-dropdown,
.ant-select-item-option,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: $pale-primary !important;
  border-radius: 4px;
  border: transparent;
  color: $primary-font;
  font-weight: 500;
  width: 100%;
  text-align: left;
}
.ant-select {
  padding: 10.5px 11px;
  font-size: 16px;
}
.ant-select-selection-placeholder {
  color: rgba($color: $primary-font, $alpha: 0.4);
}

// Steps
.ant-steps-item-icon {
  font-weight: 600;
  font-size: 18px;
}
.ant-steps-item-title::after {
  border-style: dashed;
  border-color: $disabled-gray;
  border-width: 1px;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: $primary-font;
  font-size: 14px;
  font-weight: 500;
  &:after {
    border-color: rgba($primary, 0.6);
  }
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.ant-steps-item-active .ant-steps-item-icon,
.ant-steps-item-active .ant-steps-item-icon > .ant-steps-icon {
  background-color: $dark-primary;
  color: $white;
  border-color: $dark-primary;
}
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: transparent;
}
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  font-size: 14px;
  font-weight: 300;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: $disabled-gray;
  color: $white;
  border-color: $disabled-gray;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-description {
  color: $primary-font;
  font-size: 14px;
  font-weight: 300;
}
.ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  background-color: $succcess-green;
  color: $white;
  border-color: $succcess-green;
}

// Card
.ant-card {
  color: $primary-font;
  box-shadow: transparent;
}
.ant-card-bordered {
  border-radius: 16px;
  border-color: transparent;
  background-color: $pale-primary;
}
.ant-card-hoverable {
  box-shadow: 4px 10px 40px rgba($dark-primary, 0.16);
  cursor: unset;
  background-color: $white;
}

.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 4px 10px 40px rgba($dark-primary, 0.16);
}

// Carousel
.ant-carousel .slick-dots-bottom {
  bottom: 0px;
}
.ant-carousel .slick-dots li {
  margin: 10px;
  button {
    background-color: $dark-primary;
    width: 14px;
    height: 14px;
    border-radius: 14px;
  }
}
.ant-carousel .slick-dots li.slick-active {
  margin-right: 20px;
  button {
    background-color: $dark-primary;
    width: 39px;
    height: 12px;
    border-radius: 32px;
  }
}

// Collapse
.ant-collapse,
.ant-collapse-header,
.ant-collapse-content,
.ant-collapse-content-active {
  background-color: transparent;
  border: transparent;
}
.ant-collapse-item {
  background-color: $pale-primary;
  border: transparent;
  color: $primary-font;
  font-size: 16px;
  padding: 14px 22px;
  border-radius: 8px !important;
}
.ant-collapse > .ant-collapse-item {
  border: transparent;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: $dark-primary;
  font-size: 20px;
  font-weight: 600;
}
.ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
  color: $white;
}
.ant-collapse-item-active {
  background: $primary-gradient;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 24px;
}
.ant-collapse-content {
  color: $white;
  text-align: justify;
}

// Form
.ant-form-item-label > label {
  color: $primary-font !important;
  letter-spacing: 1px;
}

// Layout
.ant-col,
.ant-row {
  margin-left: auto;
  margin-right: auto;
}
