@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

@font-face {
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Gotham/Gotham\ Light/Gotham\ Light.otf');
}

@font-face {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Gotham/Gotham\ Regular/Gotham\ Regular.otf');
}

@font-face {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Gotham/Gotham\ Medium/Gotham\ Medium.otf');
}

@font-face {
  font-family: Gotham;
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Gotham/Gotham\ ItalicBold/Gotham\ ItalicBold.otf');
}

@font-face {
  font-family: Gotham;
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/Gotham/Gotham\ Italic/Gotham\ Italic.otf');
}

@font-face {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Gotham/Gotham\ Bold/Gotham\ Bold.otf');
}

@font-face {
  font-family: Gotham;
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/Gotham/Gotham\ Bold\ Italic/Gotham\ Bold\ Italic.ttf');
}

@font-face {
  font-family: Gotham;
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Gotham/Gotham\ Black/Gotham\ Black.otf');
}
