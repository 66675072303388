/* stylelint-disable function-parentheses-space-inside */

// COLORS
$primary: #0d8aa6;
$pale-primary: #fafafa;
$dark-primary: #07668c;
$primary-font: #053742ff;
$secondary: #f2a413;
$pale-secondary: #d97a07;
$background: white;
$secondary-pink: #a63f03;

// GRAY SCALE
$dark-gray: #333;
$gray2: #4f4f4f;
$gray3: #828282;
$blocked-gray: #90909080;
$disabled-gray-background: #f0f0f0;
$pale-gray: #f2f2f2;
$disabled-gray: #eaeaea;
$white: white;

// ALERTS
$error-red: #eb5757;
$alert-orange: #ff9153;
$alert-yellow: #fedb43;
$succcess-green: #19c173;

// GRADIENTS
$primary-gradient-dark: #1049a2;
$primary-gradient-light: #39afed;
$pale-primary-gradient-dark: #eef5ff;
$pale-primary-gradient-light: #ffffff;

$primary-gradient: linear-gradient(
  153.4deg,
  $primary-gradient-light 16.68%,
  $primary-gradient-dark 217.19%
);
$pale-primary-gradient: linear-gradient(
  90deg,
  $pale-primary-gradient-dark 0%,
  $pale-primary-gradient-light 100%
);

// BREAKPOINTS
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// MEASURES
$navbar-height: 100px;
$main-padding-top: 40px;
$main-padding-right: 10px;
$main-padding-bottom: 40px;
