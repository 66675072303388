@import "../../assets/globalStyles/variables";

.MainContainer {
  text-align: center;
  width: 100%;
}

.Form {
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.SubmitButton {
  width: 100%;
}
