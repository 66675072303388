@import "../../../assets/globalStyles/variables.scss";

.Container {
  background: $primary;
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding-left: 5%;
  padding-right: 5%;
}

.Logo {
  height: 40px;
  position: relative;
  width: auto;
}

.ButtonsColumn {
  float: right;
}
