@import "../../assets/globalStyles/variables.scss";

.LayoutHeader {
  padding-top: 65px;
  background-color: $primary;
  margin: auto;
  height: max-content;
  width: 100%;
  box-shadow: 0 2px 3px rgba($pale-secondary, 0.16);
}

.MainContainer {
  background-color: $background;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 4px;
  padding-top: $main-padding-top;
  padding-right: $main-padding-right;
  padding-bottom: $main-padding-bottom;
  text-align: center;
  // height: calc(100% - $navbar-height); Netlify CD fails with this line
  height: calc(100% - 100px);
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: $sm) {
    padding: 0;
  }
}

.LayoutFooter {
  padding: 0;
}

.ComponentContainer {
  width: 96%;
  margin: 0 auto;
}
