@import '../../../assets/globalStyles/variables.scss';

.modal {
  :global {
    .ant-modal-content{
      background-color: transparent;
      box-shadow: none;
    }
  }
  .Loader {
    font-size: 125px;
    @media only screen and (max-width: $md) {
      font-size: 75px;
    }
  }
}
