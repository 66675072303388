@import "~antd/dist/antd.css";
@import "./variables";
@import "./fonts";
@import "./antd";
@import "./shared";

body {
  font-family: "Open Sans";
  margin: 0;
  color: $primary-font;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary-font;
  font-family: "Open Sans";
}

h1 {
  margin: 2.2vh auto 2vh auto;
  font-weight: 500;
  font-size: 40px;
  @media only screen and (max-width: $sm) {
    font-size: 36px;
  }
}

h2 {
  margin: 2vh auto 1.8vh auto;
  font-weight: 500;
  font-size: 24px;
}

h3 {
  margin: 1.8vh auto 1.6vh auto;
  font-weight: 500;
  font-size: 20px;
}

h4 {
  margin: 1.2vh auto 1vh auto;
  font-weight: 500;
  font-size: 16px;
}

h5 {
  margin: 1vh auto 0.8vh auto;
  font-weight: 400;
  font-size: 14px;
}

p {
  color: $primary-font;
  font-weight: 400;
  font-size: 17px;
  @media only screen and (max-width: $sm) {
    font-size: 15px;
  }
}

a {
  color: $secondary;
}
// Ant Design Components
/* stylelint-disable */

#root {
  height: 100%;
}

/* stylelint-enable */
